import React, { useState, useEffect, useCallback, createRef } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { useQrCode } from '../../hooks/useQrCode';
import { useGetUnlockPassword } from '../../hooks/useGetUnlockPassword';
import { useListPickupRequest } from '../../hooks/useListPickupRequest';
import { useListDelivery } from '../../hooks/useListDelivery';
import { useGetUser } from '../../hooks/useGetUser';
import { useGeolocation } from '../../hooks/useGeolocation';
import PageTitleBar from '../molecules/PageTitleBar';
import FooterBar from '../organisms/FooterBar';
import HeaderBar from '../organisms/HeaderBar';
import PickupRequestInfoList from '../organisms/PickupRequestInfoList';
import DeliveryReserveInfoList from '../organisms/DeliveryReserveInfoList';
import GenericTemplate from '../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    video: {
      margin: '0 auto',
    },
    videoButton: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(2),
    },
    dialogTitle: {
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.primary.main,
    },
    dialogSubTitle: {
      marginTop: theme.spacing(1),
    },
    dialogNumber: {
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      textAlign: 'center',
      letterSpacing: '5px',
    },
    snackbar: {
      // background: '#fff',
      // color: theme.palette.secondary.main,
      // fontSize: theme.typography.subtitle1.fontSize,
      marginTop: theme.spacing(1),
    },
  }),
);

const HomePage: React.FC = () => {
  const classes = useStyles({});

  const user = useGetUser();
  const {
    data: pickupRequests,
    getData: getPickupRequests,
    isLoading: isLoadingPickupRequests,
    isCompleted: isCompletedPickupRequests,
    isError: isErrorPickupRequests,
  } = useListPickupRequest();
  const {
    data: deliveries,
    getData: getDeliveries,
    isLoading: isLoadingDeliveries,
    isCompleted: isCompletedDeliveries,
    isError: isErrorDeliveries,
  } = useListDelivery();

  const isAdminUser =
    user.data.scopes.includes('business_site_user') &&
    user.data.scopes.includes('business_site_use_delivery_admin');
  const isDeliveryUser =
    user.data.scopes.includes('business_site_user') &&
    user.data.scopes.includes('business_site_use_delivery');
  const isCleaningUser =
    user.data.scopes.includes('business_site_user') &&
    user.data.scopes.includes('business_site_use_cleaning');

  // QRリーダー読み込み処理
  const canvasRef = createRef<HTMLCanvasElement>();
  const videoRef = createRef<HTMLVideoElement>();
  const qrCode = useQrCode(canvasRef, videoRef);
  const {
    coords,
    getData: getGeolocation,
    isLoading: isLoadingGeolocation,
  } = useGeolocation();
  const {
    data,
    getData: getUnlockPassword,
    isLoading: isLoadingUnlockPassword,
    isCompleted: isCompletedUnlockPassword,
    isError: isErrorUnlockPassword,
    errorType,
  } = useGetUnlockPassword();

  const [open, setOpen] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (qrCode.result && qrCode.scanned) {
      getGeolocation();
    }
  }, [qrCode, getGeolocation]);

  useEffect(() => {
    if (qrCode.result && qrCode.scanned && !isLoadingGeolocation) {
      getUnlockPassword(qrCode.result, coords);
      setOpen(true);
      qrCode.setScanned(false);
      qrCode.setResult('');
    }
  }, [qrCode, getUnlockPassword, isLoadingGeolocation, coords]);

  useEffect(() => {
    if (isCleaningUser) getPickupRequests('requested');
  }, [isCleaningUser, getPickupRequests]);

  useEffect(() => {
    if (isAdminUser) getDeliveries('reserved');
  }, [isAdminUser, getDeliveries]);

  return (
    <GenericTemplate
      header={HeaderBar}
      footer={FooterBar}
      mainContent={
        <Container maxWidth="sm">
          <Box my={3}>
            {user.isCompleted && !user.isError && isDeliveryUser && (
              <>
                <PageTitleBar title="解錠番号を表示" />
                <Button
                  variant="contained"
                  color="default"
                  className={classes.videoButton}
                  startIcon={<PhotoCamera />}
                  onClick={() => qrCode.setPlaying(!qrCode.playing)}
                  fullWidth
                >
                  {qrCode.playing
                    ? '宅配ボックスのQRコードを撮影 [停止]'
                    : '宅配ボックスのQRコードを撮影 [開始]'}
                </Button>
                <Box textAlign="center">
                  <canvas ref={canvasRef} width={328} height={246} hidden />
                  <video ref={videoRef} width={328} height={246} hidden />
                </Box>
              </>
            )}
            {user.isCompleted && !user.isError && isCleaningUser && (
              <PickupRequestInfoList
                pickupRequests={pickupRequests}
                getData={getPickupRequests}
                isError={isErrorPickupRequests}
                isCompleted={isCompletedPickupRequests}
                isLoading={isLoadingPickupRequests}
              />
            )}
            {user.isCompleted && !user.isError && isAdminUser && (
              <DeliveryReserveInfoList
                deliveries={deliveries}
                getDeliveries={getDeliveries}
                isError={isErrorDeliveries}
                isCompleted={isCompletedDeliveries}
                isLoading={isLoadingDeliveries}
              />
            )}
          </Box>
          {!isLoadingUnlockPassword && (
            <Dialog
              onClose={handleClose}
              fullWidth
              aria-labelledby="dialog-title"
              open={open}
            >
              <>
                {isErrorUnlockPassword && (
                  <>
                    {errorType === 'BadRequestError' && (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          もう一度QRを読み込んでください。
                        </DialogContentText>
                      </DialogContent>
                    )}
                    {errorType === 'InternalServerError' && (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません。システム起因で解錠出来ません。時間を置いて試してください。
                        </DialogContentText>
                      </DialogContent>
                    )}
                  </>
                )}
                {isCompletedUnlockPassword && !isErrorUnlockPassword && (
                  <>
                    {data && data.statusCode === 0 ? (
                      <>
                        <DialogTitle disableTypography id="dialog-title">
                          <Typography
                            variant="subtitle1"
                            component="p"
                            color="primary"
                            className={classes.dialogTitle}
                          >
                            宅配ボックスの解錠番号
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="p"
                            color="textSecondary"
                            className={classes.dialogSubTitle}
                          >
                            表示されている番号を、宅配ボックスのパネルに入力して下さい
                          </Typography>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText
                            id="alert-dialog-description"
                            className={classes.dialogNumber}
                          >
                            {data.password}
                          </DialogContentText>
                        </DialogContent>
                      </>
                    ) : data && data.statusCode === 1 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          開始〜確定を押して解錠し、荷物を入れて下さい
                        </DialogContentText>
                      </DialogContent>
                    ) : data && data.statusCode === 2 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません。「開始」ボタンを押すことでお家の方と通話できます。
                        </DialogContentText>
                      </DialogContent>
                    ) : data && data.statusCode === 3 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません。オーナーの設定上宅配ボックスが解錠出来ない状態です。
                        </DialogContentText>
                      </DialogContent>
                    ) : data && data.statusCode === 4 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません。現在集荷待ちのため宅配ボックスが解錠出来ない状態です。
                        </DialogContentText>
                      </DialogContent>
                    ) : data && data.statusCode === 5 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません。現在配達待ちのため宅配ボックスが解錠出来ない状態です。
                        </DialogContentText>
                      </DialogContent>
                    ) : data && data.statusCode === 8 ? (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          番号による解錠は出来ません
                        </DialogContentText>
                      </DialogContent>
                    ) : (
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          現在ご利用出来ません
                        </DialogContentText>
                      </DialogContent>
                    )}
                  </>
                )}
              </>

              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Container>
      }
    />
  );
};

export default HomePage;
