import * as React from 'react';
import { Link } from 'react-router-dom';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/logo.svg';

const useStyles = makeStyles(
  (): StyleRules => ({
    logo: {
      // TODO: svg ファイルの縦横比を1:1にしたら削除
      marginTop: '6px',
    },
  }),
);

interface HeaderBarLogoProps {
  to: string;
}

const HeaderBarLogo: React.FC<HeaderBarLogoProps> = ({ to }) => {
  const classes = useStyles({});

  return (
    <Typography variant="inherit" component="h1" className={classes.logo}>
      <Link to={to}>
        <img src={logo} height="32" alt="LIXIL" />
      </Link>
    </Typography>
  );
};

export default HeaderBarLogo;
