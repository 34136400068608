import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import PageTitleBar from '../molecules/PageTitleBar';
import { PageTabContents } from '../molecules/PageTabContents';
import DeliveryStatusLabel from '../molecules/DeliveryStatusLabel';
import LoadingIndicator from '../atoms/LoadingIndicator';
import { Delivery } from '../../hooks/useListDelivery';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    listItem: {
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    scheduleAt: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
    },
  }),
);

interface TabContentsProps {
  isLoading: boolean;
  isCompleted: boolean;
  deliveries: Delivery[];
}

const TabContents: React.FC<TabContentsProps> = ({
  isLoading,
  isCompleted,
  deliveries,
}) => {
  const classes = useStyles({});

  return isLoading ? (
    <Box mt={3}>
      <LoadingIndicator size={25} />
    </Box>
  ) : isCompleted && deliveries.length > 0 ? (
    <List disablePadding>
      {deliveries.map((delivery) => (
        <ListItem
          disableGutters
          divider
          key={delivery.trackingNumber}
          className={classes.listItem}
          button
          component={Link}
          to={`/deliveries/${delivery.deliveryId}`}
        >
          <List disablePadding>
            <ListItem disableGutters dense>
              <DeliveryStatusLabel status={delivery.status} />
              <span className={classes.scheduleAt}>
                配達予約日:&nbsp;
                {DateTime.fromFormat(
                  delivery.deliveryScheduleAt,
                  'yyyy-MM-dd',
                ).toFormat('yyyy/MM/dd')}
              </span>
            </ListItem>
            <ListItem disableGutters dense>
              お問い合わせ番号: {delivery.trackingNumber}
            </ListItem>
            <ListItem disableGutters dense>
              機器番号: {delivery.hubMacAddress}
            </ListItem>
            <ListItem disableGutters dense>
              &#12306;&nbsp;{delivery.postalCode} {delivery.address1}
              {delivery.address2 || ''}&nbsp;
              {delivery.fullName} 様
            </ListItem>
          </List>
        </ListItem>
      ))}
    </List>
  ) : (
    <Box mt={2} textAlign="center">
      予約はありません。
    </Box>
  );
};

interface DeliveryReserveInfoListProps {
  deliveries: Delivery[];
  getDeliveries: (status?: string) => void;
  isError: boolean;
  isCompleted: boolean;
  isLoading: boolean;
}

const DeliveryReserveInfoList: React.FC<DeliveryReserveInfoListProps> = ({
  deliveries,
  getDeliveries,
  isCompleted,
  isError,
  isLoading,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setTabValue(newValue);
    if (newValue === 0) getDeliveries('reserved');
    if (newValue === 1) getDeliveries();
  };

  return !isError ? (
    <>
      <Box mt={6}>
        <PageTitleBar title="配達一覧" />
      </Box>
      <PageTabContents
        labels={['配達予約中', 'すべての配達予約']}
        tabContents={[
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            deliveries={deliveries}
            key={0}
          />,
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            deliveries={deliveries}
            key={1}
          />,
        ]}
        tabValue={tabValue}
        onTabChange={handleTabChange}
      />
    </>
  ) : null;
};

export default DeliveryReserveInfoList;
