import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useFormik } from 'formik';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PickupRequestFormItem from '../molecules/PickupRequestFormItem';
import { PickupRequest } from '../../hooks/useGetPickupRequest';
import { PickupRequestApproveValues } from '../../hooks/useApprovePickupRequest';
import { PickupRequestRejectValues } from '../../hooks/useRejectPickupRequest';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    button: {
      marginTop: theme.spacing(1),
    },
    label: {
      fontWeight: 'bold',
      width: '35%',
    },
  }),
);

interface PickupRequestApproveAndRejectFormProps {
  data: PickupRequest;
  approvePickupRequest: {
    handleSubmit: (values: PickupRequestApproveValues) => void;
    isLoading: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  rejectPickupRequest: {
    handleSubmit: (values: PickupRequestApproveValues) => void;
    isLoading: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  onApproveDialogOpen: () => void;
  onRejectDialogOpen: () => void;
}

const PickupRequestApproveAndRejectForm: React.FC<PickupRequestApproveAndRejectFormProps> = ({
  data,
  approvePickupRequest,
  rejectPickupRequest,
  onApproveDialogOpen,
  onRejectDialogOpen,
}) => {
  const classes = useStyles({});

  const [isApproved, setApproved] = useState(false);
  const [isRejected, setRejected] = useState(false);
  const formik = useFormik<
    PickupRequestApproveValues | PickupRequestRejectValues
  >({
    initialValues: {
      destinationMessage: '',
    },
    onSubmit: (values) => {
      if (isApproved) {
        approvePickupRequest.handleSubmit(values);
        onApproveDialogOpen();
      }
      if (isRejected) {
        rejectPickupRequest.handleSubmit(values);
        onRejectDialogOpen();
      }
    },
  });

  const preferredDate = data.preferredDate
    ? DateTime.fromFormat(data.preferredDate, 'yyyy-MM-dd').toFormat(
        'yyyy/MM/dd',
      )
    : '';
  const preferredTimeZone = data.preferredTimeZone
    ? data.preferredTimeZone.replace(/(\d+)-(\d+)/, '$1時-$2時')
    : '';

  return (
    <>
      <Box component="dl" overflow="hidden">
        <PickupRequestFormItem
          label="申し込み日時"
          value={DateTime.fromMillis(data.createAt, {
            zone: 'UTC',
          })
            .toLocal()
            .toFormat('yyyy/MM/dd HH:mm')}
        />
        <PickupRequestFormItem
          label="集荷希望日時"
          value={`${
            preferredDate + preferredTimeZone
              ? `${preferredDate + ' ' + preferredTimeZone}`
              : ''
          }`}
        />
        <PickupRequestFormItem label="注文番号" value={data.pickupRequestId} />
        <PickupRequestFormItem label="お名前" value={data.fullName} />
        <PickupRequestFormItem
          label="ご住所"
          value={`\u3012 ${data.postalCode} ${data.address1} ${
            data.address2 || ''
          }`}
        />
        <PickupRequestFormItem
          label="電話番号"
          value={<a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {data.status === 'requested' && (
          <Box mt={2}>
            <p className={classes.label}>備考</p>
            <TextField
              name="destinationMessage"
              value={formik.values.destinationMessage}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              multiline
            />
          </Box>
        )}
        <Box my={3}>
          {data.status === 'requested' && (
            <>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                onClick={() => setApproved(true)}
              >
                お申し込みを受け付ける
              </Button>
              <Button
                className={classes.button}
                color="secondary"
                fullWidth
                type="submit"
                variant="contained"
                onClick={() => setRejected(true)}
              >
                このお申し込みを引き受けない
              </Button>
            </>
          )}
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            component={Link}
            to="/"
          >
            一覧に戻る
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PickupRequestApproveAndRejectForm;
