import { useState, useEffect } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

type scope =
  | 'members_site_user'
  | 'members_site_test_user'
  | 'business_site_user'
  | 'business_site_use_delivery'
  | 'business_site_use_cleaning'
  | 'business_site_use_delivery_admin'
  | '';

interface User {
  userId: string;
  scopes: [scope];
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  createAt: number;
}

export const useGetUser = (): {
  data: User;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<User>({
    userId: '',
    scopes: [''],
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    createAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        const response = await Axios.get(`${REACT_APP_API_URL}/user`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  }, []);

  return { data, isCompleted, isLoading, isError };
};
