const ENVELOPMENT = process.env.REACT_APP_ENVELOPMENT;

// https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia#Examples

class MediaDeviceService {
  static async getUserVideo(): Promise<MediaStream> {
    try {
      const deviceId = await (async () => {
        if (
          navigator.mediaDevices !== undefined &&
          navigator.mediaDevices.enumerateDevices !== undefined
        ) {
          const devices = await navigator.mediaDevices.enumerateDevices();

          if (ENVELOPMENT === 'development') {
            console.log('devices', devices);
          }

          const videoDevices = devices.filter((device) => {
            return device.kind === 'videoinput' && device.deviceId !== '';
          });

          if (videoDevices.length > 0) {
            const deviceId = videoDevices[videoDevices.length - 1].deviceId;

            if (ENVELOPMENT === 'development') {
              console.log('deviceId', deviceId);
            }

            return deviceId;
          }
        }

        return undefined;
      })();

      if (
        navigator.mediaDevices !== undefined &&
        navigator.mediaDevices.getUserMedia !== undefined
      ) {
        return await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: deviceId
            ? { deviceId: deviceId }
            : { facingMode: 'environment' },
        });
      }

      if (navigator.getUserMedia !== undefined) {
        return await new Promise(function (resolve, reject) {
          navigator.getUserMedia.call(
            navigator,
            {
              audio: false,
              video: deviceId ? { deviceId: deviceId } : true,
            },
            resolve,
            reject,
          );
        });
      }

      // @ts-ignore
      if (navigator.webkitGetUserMedia !== undefined) {
        return await new Promise(function (resolve, reject) {
          // @ts-ignore
          navigator.webkitGetUserMedia.call(
            navigator,
            {
              audio: false,
              video: deviceId ? { optional: [{ sourceId: deviceId }] } : true,
            },
            resolve,
            reject,
          );
        });
      }

      // @ts-ignore
      if (navigator.mozGetUserMedia !== undefined) {
        return await new Promise(function (resolve, reject) {
          // @ts-ignore
          navigator.mozGetUserMedia.call(
            navigator,
            {
              audio: false,
              video: deviceId ? { optional: [{ sourceId: deviceId }] } : true,
            },
            resolve,
            reject,
          );
        });
      }

      throw new Error('getUserMedia is not implemented in this browser.');
    } catch (e) {
      if (ENVELOPMENT === 'development') {
        console.error('error', e);
      }

      throw e;
    }
  }
}

export default MediaDeviceService;
