import React from 'react';
import Chip from '@material-ui/core/Chip';

import { DeliveryStatus } from '../../hooks/useListDelivery';

const buildLabelText = (status: DeliveryStatus): string => {
  const text = {
    scheduledReserve: '配達待ち',
    reserved: '配達待ち(予約中)',
    delivered: '配達完了',
    undeliveredForAbsence: '配達待ち(予約解除)',
    cancelledReserveBySystem: '配達待ち(予約解除)',
    cancelledReserveByMember: '配達待ち(予約解除)',
    received: '受取り済み',
    unknown: '不明',
  };
  return text[status];
};

interface DeliveryStatusLabelProps {
  status: DeliveryStatus;
}

const DeliveryStatusLabel: React.FC<DeliveryStatusLabelProps> = ({
  status,
}) => {
  const isWaitingDelivery =
    status === 'scheduledReserve' ||
    status === 'reserved' ||
    status === 'undeliveredForAbsence' ||
    status === 'cancelledReserveBySystem' ||
    status === 'cancelledReserveByMember';
  return (
    <Chip
      size="small"
      label={buildLabelText(status)}
      color={isWaitingDelivery ? 'secondary' : 'default'}
      style={{ fontWeight: 'bold', borderRadius: '5px' }}
    />
  );
};

export default DeliveryStatusLabel;
