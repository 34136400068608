import * as React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface MainContentOnlyTemplateProps {
  mainContent: React.ReactNode;
  footer?: React.FunctionComponent;
}

const useStyles = makeStyles(
  (): StyleRules => ({
    root: {
      height: '100vh',
      position: 'relative',
      backgroundColor: '#f1f5f7',
    },
    mainContent: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footer: {
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
  }),
);

const MainContentOnlyTemplate: React.FC<MainContentOnlyTemplateProps> = (
  props,
) => {
  const { mainContent, footer: Footer } = props;
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.mainContent}>{mainContent}</div>
      {Footer && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default MainContentOnlyTemplate;
