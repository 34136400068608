import * as React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(
  (): StyleRules => ({
    circularProgressWrapper: {
      height: '100%',
    },
  }),
);

interface LoadingIndicatorProps {
  size?: number;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ size }) => {
  const classes = useStyles({});

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.circularProgressWrapper}
    >
      <CircularProgress size={size} />
    </Grid>
  );
};

export default LoadingIndicator;
