import * as Querystring from 'querystring';

import Axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { NodeRequestor } from '@openid/appauth/built/node_support/node_requestor';
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocationLike,
  RedirectRequestHandler,
  StringMap,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { DateTime } from 'luxon';

const CLIENT_ID = process.env.REACT_APP_JANRAIN_CLIENT_ID!;
const CUSTOMER_ID = process.env.REACT_APP_JANRAIN_CUSTOMER_ID!;
const DOMAIN = process.env.REACT_APP_JANRAIN_DOMAIN!;
const SECURE_COOKIE =
  process.env.REACT_APP_SECURE_COOKIE! === 'true' ? true : false;
const MY_LIXIL_DOMAIN = process.env.REACT_APP_MY_LIXIL_DOMAIN!;

const DEFAULT_SCOPE = 'openid profile email';
const DEFAULT_UI_LOCALE = 'ja-JP';
const TOKEN_INFO = 'auth.service.token_info';

class QueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike, useQuery?: boolean) {
    if (useQuery) {
      return this.parseQueryString(input.search);
    } else {
      return this.parseQueryString(input.hash);
    }
  }
}

const requestor = new NodeRequestor();
const parser = new QueryStringUtils();

export class AuthServiceError extends Error {
  constructor() {
    super();
    this.name = 'AuthServiceError';
  }
}

export class InvaildUpdateTokenError extends AuthServiceError {
  error: Error;

  constructor(error: Error) {
    super();
    this.name = 'InvaildUpdateTokenError';
    this.error = error;
  }
}

export interface HandleAuthenticationOptions {
  redirectUri: string;
}

export interface LoginOptions {
  redirectUri: string;
  prompt?: 'none' | 'login';
  uiLocale?: string;
}

export interface LogoutOptions {
  redirectTo: string;
}

export interface TokenInfo extends TokenResponse {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  issuedAt: number;
  refreshToken: string;
  scope: string;
}

export interface UpdateTokenOptions {
  redirectUri: string;
  refreshToken: string;
}

export interface UserInfo {
  email: string;
  emailVerified: boolean;
  userId: string;
  userName?: string;
}

class AuthClient {
  private notifier: AuthorizationNotifier;
  private authorizationHandler: RedirectRequestHandler;
  private tokenHandler: BaseTokenRequestHandler;

  private configuration: AuthorizationServiceConfiguration;
  private code: string | undefined;
  private extras: StringMap | undefined;

  constructor() {
    const baseRequestUri = `https://${DOMAIN}/${CUSTOMER_ID}`;

    this.configuration = new AuthorizationServiceConfiguration({
      authorization_endpoint: `${baseRequestUri}/login/authorize`,
      end_session_endpoint: `${baseRequestUri}/auth-ui/logout`,
      revocation_endpoint: `${baseRequestUri}/login/token/revoke`,
      token_endpoint: `${baseRequestUri}/login/token`,
      userinfo_endpoint: `${baseRequestUri}/profiles/oidc/userinfo`,
    });

    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(undefined, parser);
    this.tokenHandler = new BaseTokenRequestHandler(requestor);

    this.authorizationHandler.setAuthorizationNotifier(this.notifier);

    this.notifier.setAuthorizationListener((request, response) => {
      if (request.internal && response) {
        this.extras = {};
        this.extras.code_verifier = request.internal.code_verifier;
        this.code = response.code;
      }
    });
  }

  /**
   * アクセストークンを取得
   */
  getAccessToken(): string {
    const response = this.getTokenInfo();

    if (!response) {
      throw new AuthServiceError();
    }

    return response.accessToken;
  }

  /**
   * IDトークンを取得
   */
  getIdToken(): string {
    const response = this.getTokenInfo();

    if (!response) {
      throw new AuthServiceError();
    }

    return response.idToken;
  }

  /**
   * リフレッシュトークンを取得
   */
  getRefreshToken(): string {
    const response = this.getTokenInfo();

    if (!response) {
      throw new AuthServiceError();
    }

    return response.refreshToken;
  }

  /**
   * トークン情報を取得
   */
  getTokenInfo(): TokenInfo {
    const response = Cookies.get(TOKEN_INFO);

    if (!response) {
      throw new AuthServiceError();
    }

    return JSON.parse(response);
  }

  /**
   * ユーザー情報を取得
   */
  async getUserInfo(): Promise<UserInfo> {
    const endpoint = this.configuration.userInfoEndpoint!;
    const accessToken = this.getAccessToken();

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await Axios.get(endpoint, config);
      const data = response.data;

      const userInfo: UserInfo = {
        email: data.email,
        emailVerified: data.email_verified,
        userId: data.sub,
        userName: data.preferred_username,
      };

      return userInfo;
    } catch {
      throw new AuthServiceError();
    }
  }

  /**
   * 認証コードでトークンを取得
   * @param handleAuthenticationOptions
   */
  async handleAuthentication(
    handleAuthenticationOptions: HandleAuthenticationOptions,
  ): Promise<void> {
    await this.authorizationHandler.completeAuthorizationRequestIfPossible();

    if (!this.code) {
      Cookies.remove(TOKEN_INFO);
      return;
    }

    const redirectUri = handleAuthenticationOptions.redirectUri;

    const request = new TokenRequest({
      client_id: CLIENT_ID,
      code: this.code,
      extras: this.extras,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      redirect_uri: redirectUri,
    });

    try {
      const response = await this.tokenHandler.performTokenRequest(
        this.configuration,
        request,
      );
      Cookies.set(TOKEN_INFO, JSON.stringify(response), {
        secure: SECURE_COOKIE,
        sameSite: 'Lax',
        expires: 30,
      });
    } catch {
      Cookies.remove(TOKEN_INFO);
    }
  }

  /**
   * 認証状態の検証
   */
  isAuthenticated(): boolean {
    try {
      const tokenResponse = this.getTokenInfo();
      const currentTime = DateTime.utc().toSeconds();
      const expiresIn = tokenResponse.expiresIn;
      const issuedAt = tokenResponse.issuedAt;
      const expiredAt = expiresIn + issuedAt;
      if (currentTime < expiredAt - 300) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  /**
   * ログイン処理
   * @param loginOptions
   */
  login(loginOptions: LoginOptions): void {
    const responseType = AuthorizationRequest.RESPONSE_TYPE_CODE;

    const redirectUri = loginOptions.redirectUri;
    const prompt = loginOptions.prompt || 'none';
    const uiLocales = loginOptions.uiLocale || DEFAULT_UI_LOCALE;

    const request = new AuthorizationRequest({
      client_id: CLIENT_ID,
      extras: {
        prompt: prompt,
        ui_locales: uiLocales,
      },
      redirect_uri: redirectUri,
      response_type: responseType,
      scope: DEFAULT_SCOPE,
    });

    this.authorizationHandler.performAuthorizationRequest(
      this.configuration,
      request,
    );
  }

  /**
   * ログアウト処理
   * @param logoutOptions
   */
  logout(logoutOptions: LogoutOptions): void {
    const endpoint = `https://${MY_LIXIL_DOMAIN}/ja/logout`;

    const query = Querystring.stringify({
      return_url: logoutOptions.redirectTo,
    });

    window.location.assign(`${endpoint}?${query}`);
  }

  getProfileLink(): string {
    const endpoint = `https://${DOMAIN}/${CUSTOMER_ID}/auth-ui/profile`;

    const query = Querystring.stringify({
      client_id: CLIENT_ID,
    });

    return `${endpoint}?${query}`;
  }

  /**
   * トークン更新処理
   */
  async updateToken(updateTokenOptions: UpdateTokenOptions): Promise<void> {
    const redirectUri = updateTokenOptions.redirectUri;
    const refreshToken = updateTokenOptions.refreshToken;

    const request = new TokenRequest({
      client_id: CLIENT_ID,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      redirect_uri: redirectUri,
      refresh_token: refreshToken,
    });

    try {
      const response = await this.tokenHandler.performTokenRequest(
        this.configuration,
        request,
      );
      Cookies.set(TOKEN_INFO, JSON.stringify(response), {
        secure: SECURE_COOKIE,
        sameSite: 'Lax',
        expires: 30,
      });
    } catch (e) {
      Cookies.remove(TOKEN_INFO);
      throw new InvaildUpdateTokenError(e);
    }
  }
}

const AuthService = new AuthClient();

export default AuthService;
