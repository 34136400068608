import { useState } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

export const usePickedupPickupRequest = (
  userId?: string,
  pickupRequestId?: string,
): {
  handleSubmit: () => void;
  isLoading: boolean;
  isError: boolean;
  isCompleted: boolean;
  isRefetched: boolean;
  setIsRefetched: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRefetched, setIsRefetched] = useState(false);

  const handleSubmit = () => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);
      setIsRefetched(false);

      try {
        const idToken = AuthService.getIdToken();

        await Axios.post(
          `${REACT_APP_API_URL}/users/${userId}/pickup-requests/${pickupRequestId}/pickedup`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        setIsCompleted(true);
        setIsError(false);
      } catch (e) {
        setIsCompleted(false);
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return {
    handleSubmit,
    isLoading,
    isError,
    isCompleted,
    isRefetched,
    setIsRefetched,
  };
};
