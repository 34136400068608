import * as React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    menuIcon: {
      marginLeft: 'auto',
    },
    logoutText: {
      color: theme.palette.secondary.main,
    },
  }),
);

interface HeaderBarMenuProps {
  onLogoutClick: () => void;
}

const HeaderBarMenu: React.FC<HeaderBarMenuProps> = ({ onLogoutClick }) => {
  const classes = useStyles({});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleCloseMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <div className={classes.menuIcon}>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="inherit"
        edge="end"
        onClick={handleOpenMenu}
      >
        <SettingsApplicationsIcon fontSize="large" />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem className={classes.logoutText} onClick={onLogoutClick}>
          ログアウト
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HeaderBarMenu;
