import React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    tabsWrapper: {
      background: '#fafafa',
      borderBottom: '1px solid #e8e8e8',
      marginTop: theme.spacing(2),
    },
    tabs: {
      justifyContent: 'space-between',
    },
    tab: {
      minWidth: 72,
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }),
);

interface PageTabContentsProps {
  labels: string[];
  tabContents: React.ReactNode[];
  tabValue: number;
  onTabChange: (
    event: React.ChangeEvent<unknown>,
    newTabsValue: number,
  ) => void;
}

export const PageTabContents: React.FC<PageTabContentsProps> = ({
  labels,
  tabContents,
  tabValue,
  onTabChange,
}) => {
  const classes = useStyles({});

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={classes.tabsWrapper}
      >
        <Tabs
          value={tabValue}
          onChange={onTabChange}
          aria-label="tabs"
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
          classes={
            tabContents.length >= 4
              ? {
                  flexContainer: classes.tabs,
                }
              : {}
          }
        >
          {labels.map((label, index) => {
            return <Tab key={index} label={label} className={classes.tab} />;
          })}
        </Tabs>
      </AppBar>
      {tabContents[tabValue]}
    </>
  );
};
