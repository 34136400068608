class GeolocationService {
  static getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>(
      (
        resolve: (position: GeolocationPosition) => void,
        reject: (positionError: GeolocationPositionError) => void,
      ) => {
        if (!navigator.geolocation) {
          const error: GeolocationPositionError = {
            code: 0,
            message: 'geolocation not supported.',
            PERMISSION_DENIED: 0,
            POSITION_UNAVAILABLE: 0,
            TIMEOUT: 0,
          };
          reject(error);
        }
        const geolocation: Geolocation = navigator.geolocation;
        const successCallback: PositionCallback = (
          position: GeolocationPosition,
        ): void => {
          resolve(position);
        };
        const errorCallback: PositionErrorCallback = (
          positionError: GeolocationPositionError,
        ): void => {
          reject(positionError);
        };
        const options: PositionOptions = {
          enableHighAccuracy: true,
          // maximumAge: number,
          // timeout: number,
        };

        geolocation.getCurrentPosition(successCallback, errorCallback, options);
      },
    );
  }
}

export default GeolocationService;
