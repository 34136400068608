import React from 'react';
import Chip from '@material-ui/core/Chip';

import { PickupRequestStatus } from '../../hooks/useGetPickupRequest';

const buildLabelText = (status: PickupRequestStatus): string => {
  const text = {
    requested: '受付待ち',
    requestCanceled: '申込キャンセル',
    approved: '集荷可能',
    approveRejected: '受付キャンセル',
    pickedup: '集荷完了',
    delivering: '配達中',
    delivered: '配達完了',
    unknown: '不明',
  };
  return text[status];
};

interface PickupRequestStatusLabelProps {
  status: PickupRequestStatus;
}

const PickupRequestStatusLabel: React.FC<PickupRequestStatusLabelProps> = ({
  status,
}) => {
  return (
    status && (
      <Chip
        variant="outlined"
        size="small"
        label={buildLabelText(status)}
        color="secondary"
      />
    )
  );
};

export default PickupRequestStatusLabel;
