import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import GenericTemplate from '../templates/GenericTemplate';
import HeaderBar from '../organisms/HeaderBar';
import FooterBar from '../organisms/FooterBar';
import PageTitleBar from '../molecules/PageTitleBar';
import MessageBox from '../molecules/MessageBox';
import DeliveryStatusLabel from '../molecules/DeliveryStatusLabel';
import DeliveryReserveDetailItem from '../molecules/DeliveryReserveDetailItem';
import LoadingIndicator from '../atoms/LoadingIndicator';
import { useGetDelivery } from '../../hooks/useGetDelivery';

const DeliveryReserveDetailPage: React.FC = () => {
  const { deliveryId } = useParams<{ deliveryId: string }>();
  const { getData, data, isLoading, isCompleted, isError } = useGetDelivery();

  useEffect(() => {
    getData(deliveryId);
  }, [getData, deliveryId]);

  return (
    <GenericTemplate
      header={HeaderBar}
      footer={FooterBar}
      mainContent={
        isLoading ? (
          <LoadingIndicator />
        ) : (
          <Container maxWidth="sm">
            {isError && (
              <Box mt={4} textAlign="center">
                <MessageBox color="secondary">
                  配達依頼情報の取得に失敗しました。
                </MessageBox>
              </Box>
            )}
            {isCompleted && !isError && (
              <>
                <Box display="flex" alignItems="center" mt={3}>
                  <PageTitleBar title="配達詳細" />
                  <Box ml={2}>
                    <DeliveryStatusLabel status={data.status} />
                  </Box>
                </Box>
                <Box component="dl" overflow="hidden">
                  <DeliveryReserveDetailItem
                    label="配達予約日"
                    value={DateTime.fromFormat(
                      data.deliveryScheduleAt,
                      'yyyy-MM-dd',
                    ).toFormat('yyyy/MM/dd')}
                  />
                  <DeliveryReserveDetailItem
                    label="お問い合わせ番号"
                    value={data.trackingNumber}
                  />
                  <DeliveryReserveDetailItem
                    label="機器番号"
                    value={data.hubMacAddress}
                  />
                  <DeliveryReserveDetailItem
                    label="お名前"
                    value={data.fullName}
                  />
                  <DeliveryReserveDetailItem
                    label="住所"
                    value={`\u3012 ${data.postalCode} ${data.address1} ${
                      data.address2 || ''
                    }`}
                  />
                  <DeliveryReserveDetailItem
                    label="電話番号"
                    value={
                      <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
                    }
                  />
                </Box>
              </>
            )}
            <Box mt={4}>
              <Button fullWidth variant="contained" component={Link} to="/">
                一覧に戻る
              </Button>
            </Box>
          </Container>
        )
      }
    />
  );
};

export default DeliveryReserveDetailPage;
