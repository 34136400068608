import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import GoogleMapReact, { Coords } from 'google-map-react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import GenericTemplate from '../templates/GenericTemplate';
import HeaderBar from '../organisms/HeaderBar';
import FooterBar from '../organisms/FooterBar';
import PickupRequestApproveAndRejectForm from '../organisms/PickupRequestApproveAndRejectForm';
import PickupRequestPickedupForm from '../organisms/PickupRequestPickedupForm';
import PickupRequestDeliveringForm from '../organisms/PickupRequestDeliveringForm';
import PickupRequestDeliveredForm from '../organisms/PickupRequestDeliveredForm';
import PickupRequestCompletedDialog from '../organisms/PickupRequestCompletedDialog';
import PageTitleBar from '../molecules/PageTitleBar';
import PickupRequestStatusLabel from '../molecules/PickupRequestStatusLabel';
import MessageBox from '../molecules/MessageBox';
import LoadingIndicator from '../atoms/LoadingIndicator';
import { useDialog } from '../../hooks/useDialog';
import { useGetPickupRequest } from '../../hooks/useGetPickupRequest';
import { useApprovePickupRequest } from '../../hooks/useApprovePickupRequest';
import { useRejectPickupRequest } from '../../hooks/useRejectPickupRequest';
import { usePickedupPickupRequest } from '../../hooks/usePickedupPickupRequest';
import { useDeliveringPickupRequest } from '../../hooks/useDeliveringPickupRequest';
import { useDeliveredPickupRequest } from '../../hooks/useDeliveredPickupRequest';
import { useGeolocation } from '../../hooks/useGeolocation';

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

const handleApiLoaded = (
  // eslint-disable-next-line
  map: any,
  // eslint-disable-next-line
  maps: any,
  coords: Coords,
  address: string,
) => {
  const directionsService: google.maps.DirectionsService = new maps.DirectionsService();
  const directionsRenderer: google.maps.DirectionsRenderer = new maps.DirectionsRenderer();

  const request: google.maps.DirectionsRequest = {
    origin: {
      lat: coords.lat,
      lng: coords.lng,
    },
    destination: address,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  directionsService.route(request, (response, status) => {
    if (status === google.maps.DirectionsStatus.OK) {
      directionsRenderer.setDirections(response);
      directionsRenderer.setMap(map);
    } else {
      console.error(status);
    }
  });
};

const PickupRequestDetailPage: React.FC = () => {
  const { userId, pickupRequestId } = useParams<{
    userId: string;
    pickupRequestId: string;
  }>();

  const pickupRequest = useGetPickupRequest(userId, pickupRequestId);
  const approvePickupRequest = useApprovePickupRequest(userId, pickupRequestId);
  const rejectPickupRequest = useRejectPickupRequest(userId, pickupRequestId);
  const pickedupPickupRequest = usePickedupPickupRequest(
    userId,
    pickupRequestId,
  );
  const deliveringPickupRequest = useDeliveringPickupRequest(
    userId,
    pickupRequestId,
  );
  const deliveredPickupRequest = useDeliveredPickupRequest(
    userId,
    pickupRequestId,
  );

  const { coords, getData, isCompleted, isError } = useGeolocation();
  useEffect(() => {
    getData();
  }, [getData]);

  const approveCompletedDialog = useDialog();
  const rejectCompletedDialog = useDialog();
  const pickedupCompletedDialog = useDialog();
  const deliveringCompletedDialog = useDialog();
  const deliveredCompletedDialog = useDialog();

  useEffect(() => {
    if (approvePickupRequest.isCompleted && !approvePickupRequest.isRefetched) {
      pickupRequest.fetchData();
      approvePickupRequest.setIsRefetched(true);
    }
  }, [approvePickupRequest, pickupRequest]);

  useEffect(() => {
    if (rejectPickupRequest.isCompleted && !rejectPickupRequest.isRefetched) {
      pickupRequest.fetchData();
      rejectPickupRequest.setIsRefetched(true);
    }
  }, [rejectPickupRequest, pickupRequest]);

  useEffect(() => {
    if (
      pickedupPickupRequest.isCompleted &&
      !pickedupPickupRequest.isRefetched
    ) {
      pickupRequest.fetchData();
      pickedupPickupRequest.setIsRefetched(true);
    }
  }, [pickedupPickupRequest, pickupRequest]);

  useEffect(() => {
    if (
      deliveringPickupRequest.isCompleted &&
      !deliveringPickupRequest.isRefetched
    ) {
      pickupRequest.fetchData();
      deliveringPickupRequest.setIsRefetched(true);
    }
  }, [deliveringPickupRequest, pickupRequest]);

  useEffect(() => {
    if (
      deliveredPickupRequest.isCompleted &&
      !deliveredPickupRequest.isRefetched
    ) {
      pickupRequest.fetchData();
      deliveredPickupRequest.setIsRefetched(true);
    }
  }, [deliveredPickupRequest, pickupRequest]);

  return (
    <GenericTemplate
      header={HeaderBar}
      footer={FooterBar}
      mainContent={
        pickupRequest.isLoading ||
        approvePickupRequest.isLoading ||
        rejectPickupRequest.isLoading ||
        pickedupPickupRequest.isLoading ||
        deliveringPickupRequest.isLoading ||
        deliveredPickupRequest.isLoading ? (
          <LoadingIndicator />
        ) : (
          <Container maxWidth="sm">
            {pickupRequest.isError && (
              <Box mt={4} textAlign="center">
                <MessageBox color="secondary">
                  お申し込み情報の取得に失敗しました。
                </MessageBox>
                <Box mt={2}>
                  <Button fullWidth variant="contained" component={Link} to="/">
                    一覧に戻る
                  </Button>
                </Box>
              </Box>
            )}
            {pickupRequest.isCompleted && !pickupRequest.isError && (
              <>
                <Box display="flex" alignItems="center" mt={3}>
                  <PageTitleBar title="お申し込み詳細" />
                  <Box ml={2}>
                    <PickupRequestStatusLabel
                      status={pickupRequest.data.status}
                    />
                  </Box>
                </Box>
                {isCompleted && !isError && (
                  <Box mt={2} height={300}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: REACT_APP_GOOGLE_MAP_API_KEY,
                      }}
                      center={{
                        lat: coords.lat,
                        lng: coords.lng,
                      }}
                      defaultZoom={15}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        handleApiLoaded(
                          map,
                          maps,
                          coords,
                          pickupRequest.data.address1 +
                            (pickupRequest.data.address2 || ''),
                        )
                      }
                    />
                  </Box>
                )}
                {/* PickupRequest Form */}
                {(pickupRequest.data.status === 'requested' ||
                  pickupRequest.data.status === 'requestCanceled' ||
                  pickupRequest.data.status === 'approveRejected') && (
                  <PickupRequestApproveAndRejectForm
                    data={pickupRequest.data}
                    approvePickupRequest={approvePickupRequest}
                    rejectPickupRequest={rejectPickupRequest}
                    onApproveDialogOpen={approveCompletedDialog.handleOpen}
                    onRejectDialogOpen={rejectCompletedDialog.handleOpen}
                  />
                )}
                {pickupRequest.data.status === 'approved' && (
                  <PickupRequestPickedupForm
                    data={pickupRequest.data}
                    pickedupPickupRequest={pickedupPickupRequest}
                    onPickedupDialogOpen={pickedupCompletedDialog.handleOpen}
                  />
                )}
                {pickupRequest.data.status === 'pickedup' && (
                  <PickupRequestDeliveringForm
                    data={pickupRequest.data}
                    deliveringPickupRequest={deliveringPickupRequest}
                    onDeliveringDialogOpen={
                      deliveringCompletedDialog.handleOpen
                    }
                  />
                )}
                {(pickupRequest.data.status === 'delivering' ||
                  pickupRequest.data.status === 'delivered') && (
                  <PickupRequestDeliveredForm
                    data={pickupRequest.data}
                    deliveredPickupRequest={deliveredPickupRequest}
                    onDeliveredDialogOpen={deliveredCompletedDialog.handleOpen}
                  />
                )}
                {/* Completed & Error Dialog */}
                {approvePickupRequest.isCompleted && (
                  <PickupRequestCompletedDialog
                    title="集荷依頼受付"
                    text="集荷依頼の受付を完了しました。"
                    open={approveCompletedDialog.open}
                    onClose={approveCompletedDialog.handleClose}
                  />
                )}
                {approvePickupRequest.isError && (
                  <Box mt={1} textAlign="center">
                    <MessageBox color="secondary">
                      受け付けに失敗しました。
                    </MessageBox>
                  </Box>
                )}
                {rejectPickupRequest.isCompleted && (
                  <PickupRequestCompletedDialog
                    title="集荷依頼受付"
                    text="集荷依頼の受付キャンセルを完了しました。"
                    open={rejectCompletedDialog.open}
                    onClose={rejectCompletedDialog.handleClose}
                  />
                )}
                {rejectPickupRequest.isError && (
                  <Box mt={1} textAlign="center">
                    <MessageBox color="secondary">
                      受け付けキャンセルに失敗しました。
                    </MessageBox>
                  </Box>
                )}
                {pickedupPickupRequest.isCompleted && (
                  <PickupRequestCompletedDialog
                    title="集荷依頼状況"
                    text="集荷依頼状況を集荷済みに変更しました。'"
                    open={pickedupCompletedDialog.open}
                    onClose={pickedupCompletedDialog.handleClose}
                  />
                )}
                {pickedupPickupRequest.isError && (
                  <Box mt={1} textAlign="center">
                    <MessageBox color="secondary">
                      集荷済みステータスへの更新が失敗しました。
                    </MessageBox>
                  </Box>
                )}
                {deliveringPickupRequest.isCompleted && (
                  <PickupRequestCompletedDialog
                    title="集荷依頼状況"
                    text="集荷依頼状況を配達中に変更しました。"
                    open={deliveringCompletedDialog.open}
                    onClose={deliveringCompletedDialog.handleClose}
                  />
                )}
                {deliveringPickupRequest.isError && (
                  <Box mt={1} textAlign="center">
                    <MessageBox color="secondary">
                      配達中ステータスへの更新が失敗しました。
                    </MessageBox>
                  </Box>
                )}
                {deliveredPickupRequest.isCompleted && (
                  <PickupRequestCompletedDialog
                    title="集荷依頼状況"
                    text="集荷依頼状況を配達済みに変更しました。"
                    open={deliveredCompletedDialog.open}
                    onClose={deliveredCompletedDialog.handleClose}
                  />
                )}
                {deliveredPickupRequest.isError && (
                  <Box mt={1} textAlign="center">
                    <MessageBox color="secondary">
                      配達済みステータスへの更新が失敗しました。
                    </MessageBox>
                  </Box>
                )}
              </>
            )}
          </Container>
        )
      }
    />
  );
};

export default PickupRequestDetailPage;
