import { useState } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

export const useGetUnlockPassword = (): {
  data: {
    statusCode: number;
    status: string;
    password: number | null;
  };
  getData: (
    qrCode: string,
    coords: {
      lat: number;
      lng: number;
    },
  ) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorType: string;
} => {
  const [data, setData] = useState({
    statusCode: 0,
    status: '',
    password: null,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState('');

  const getData = (qrCode: string, coords: { lat: number; lng: number }) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        const response = await Axios.get(
          `${REACT_APP_API_URL}/delivery-boxes/${encodeURIComponent(
            qrCode,
          )}/unlock-password`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            params: {
              lat: coords.lat,
              lon: coords.lng,
            },
          },
        );

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
        if (e.response.status === 400) {
          setErrorType('BadRequestError');
        } else if (e.response.status === 401) {
          setErrorType('UnauthorizedError');
        } else if (e.response.status === 500) {
          setErrorType('InternalServerError');
        } else {
          setErrorType('ExternalServiceError');
        }
      }

      setIsLoading(false);
    })();
  };

  return { data, getData, isCompleted, isLoading, isError, errorType };
};
