import { useState, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

/**
 * 集荷依頼の状態
 *
 * @returns requested: 申込済み
 * @returns requestCanceled: 申込キャンセル
 * @returns approved: 受付済み
 * @returns approveRejected: 受付キャンセル
 */
export type PickupRequestStatus =
  | 'requested'
  | 'requestCanceled'
  | 'approved'
  | 'approveRejected'
  | 'pickedup'
  | 'delivering'
  | 'delivered'
  | 'unknown';

export interface PickupRequest {
  userId: string;
  pickupRequestId: string;
  fullName: string;
  postalCode: string;
  address1: string;
  address2?: string;
  phoneNumber: string;
  preferredDate?: string;
  preferredTimeZone?: string;
  memo?: string;
  destinationMessage?: string;
  status: PickupRequestStatus;
  cancelAt?: number;
  updateAt: number;
  createAt: number;
}

export const useListPickupRequest = (): {
  data: PickupRequest[];
  getData: (status?: string) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<PickupRequest[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (status?: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(`${REACT_APP_API_URL}/pickup-requests`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        params: {
          status,
        },
      });

      setData(response.data.pickupRequests);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { data, getData, isCompleted, isLoading, isError };
};
