import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import logo from '../../assets/logo-lixil.svg';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    footer: {
      backgroundColor: '#f1f5f7',
    },
    footerLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
    },
    footerLink: {
      width: 'auto',
      color: theme.palette.common.black,
      paddingTop: 0,
      paddingBottom: 0,
    },
    divider: {
      height: '1rem',
    },
    logo: {
      verticalAlign: 'middle',
    },
  }),
);

const FooterBar: React.FC = () => {
  const classes = useStyles({});

  return (
    <footer className={classes.footer}>
      <List className={classes.footerLinks} disablePadding>
        <ListItem
          component="a"
          href="https://manual.smartexterior.net"
          target="_blank"
          className={classes.footerLink}
        >
          オンラインヘルプ
        </ListItem>
        <Divider orientation="vertical" className={classes.divider} />
        <ListItem component={Link} to="/terms" className={classes.footerLink}>
          利用規約
        </ListItem>
        <Divider orientation="vertical" className={classes.divider} />
        <ListItem component={Link} to="/privacy" className={classes.footerLink}>
          プライバシーポリシー
        </ListItem>
      </List>
      <Box py={2} textAlign="center">
        <img src={logo} height="32" alt="LIXIL" className={classes.logo} />
      </Box>
    </footer>
  );
};

export default FooterBar;
