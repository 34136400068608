export const version = (): void => {
  const html = document.documentElement.outerHTML;
  const root = document.getElementById('root')!;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const hash = doc.documentElement.outerHTML.replace(/\r?\n/g, '');

  const observer = new MutationObserver(async () => {
    const date = new Date();
    const timestamp = date.getTime();

    const response = await fetch(`/?${timestamp}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
      },
    });

    const _html = await response.text();
    const _doc = parser.parseFromString(_html, 'text/html');
    const _hash = _doc.documentElement.outerHTML
      .replace(/\r?\n/g, '')
      .replace(/<script src="\/main\..*?\.hot-update\.js"><\/script>/g, '');

    if (hash !== _hash) {
      window.location.reload();
    }
  });

  observer.observe(root, {
    childList: true,
  });
};

version();
