import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import logo from '../../assets/logo-blue.svg';
import AuthService from '../../utils/AuthService';
import FooterBar from '../organisms/FooterBar';
import MainContentOnlyTemplate from '../templates/MainContentTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    paper: {
      width: '325px',
      textAlign: 'center',
      padding: '48px 32px 64px',
    },
    subText: {
      fontSize: theme.typography.h5.fontSize,
    },
    button: {
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(4),
    },
  }),
);

const LoginPage: React.FC = () => {
  const classes = useStyles({});
  const handleLoginClick = React.useCallback(() => {
    AuthService.login({
      redirectUri: `${window.location.origin}/callback`,
      prompt: 'login',
    });
  }, []);

  return (
    <MainContentOnlyTemplate
      footer={FooterBar}
      mainContent={
        <Paper className={classes.paper}>
          <img src={logo} height="75" alt="LIXIL" />
          <Typography variant="h4" component="h1" color="primary">
            <Box fontWeight="fontWeightBold" pt={1}>
              あずけるBOX
              <br />
              <span className={classes.subText}>ビジネスサイト</span>
            </Box>
          </Typography>
          <Button
            type="button"
            variant="contained"
            fullWidth
            color="primary"
            disabled={false}
            onClick={handleLoginClick}
            className={classes.button}
          >
            <Box fontWeight="fontWeightBold">ログインページへ</Box>
          </Button>
        </Paper>
      }
    />
  );
};

export default LoginPage;
