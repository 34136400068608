import * as React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
  (): StyleRules => ({
    title: {
      fontWeight: 'bold',
    },
  }),
);

interface PageTitleBarPresenterProps {
  title: string;
}

export const PageTitleBarPresenter: React.FC<PageTitleBarPresenterProps> = ({
  title,
}) => {
  const classes = useStyles({});

  return (
    <Typography
      variant="h5"
      component="h2"
      color="primary"
      className={classes.title}
    >
      {title}
    </Typography>
  );
};

export default PageTitleBarPresenter;
