import React, { useEffect, useState } from 'react';

import AuthService, { UserInfo } from '../../utils/AuthService';
import FooterBar from '../organisms/FooterBar';
import HeaderBar from '../organisms/HeaderBar';
import GenericTemplate from '../templates/GenericTemplate';

const AccountPage: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();

  useEffect(() => {
    (async () => {
      const userInfo = await AuthService.getUserInfo();
      setUserInfo(userInfo);
    })();
  }, []);

  return (
    <GenericTemplate
      header={HeaderBar}
      footer={FooterBar}
      mainContent={
        <pre>
          <code>
            <>{JSON.stringify(userInfo, null, 2)}</>
          </code>
        </pre>
      }
    />
  );
};

export default AccountPage;
