import { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

/**
 * 集荷依頼の状態
 *
 * @returns requested: 申込済み
 * @returns requestCanceled: 申込キャンセル
 * @returns approved: 受付済み
 * @returns approveRejected: 受付取り消し
 * @returns pickedUp: 集荷済み
 * @returns delivering: 配達中
 * @returns delivered: 配達済み
 */
export type PickupRequestStatus =
  | 'requested'
  | 'requestCanceled'
  | 'approved'
  | 'approveRejected'
  | 'pickedup'
  | 'delivering'
  | 'delivered'
  | 'unknown';

export interface PickupRequest {
  userId: string;
  pickupRequestId: string;
  fullName: string;
  postalCode: string;
  address1: string;
  address2?: string;
  phoneNumber: string;
  preferredDate?: string;
  preferredTimeZone?: string;
  memo?: string;
  destinationMessage?: string;
  status: PickupRequestStatus;
  cancelAt?: number;
  updateAt: number;
  createAt: number;
}

export const useGetPickupRequest = (
  userId?: string,
  pickupRequestId?: string,
): {
  fetchData: () => Promise<void>;
  data: PickupRequest;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<PickupRequest>({
    userId: '',
    pickupRequestId: '',
    fullName: '',
    postalCode: '',
    address1: '',
    address2: '',
    phoneNumber: '',
    preferredDate: '',
    preferredTimeZone: '',
    memo: '',
    status: 'unknown',
    cancelAt: 0,
    updateAt: 0,
    createAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const fetchData = useCallback(async () => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(
        `${REACT_APP_API_URL}/users/${userId}/pickup-requests/${pickupRequestId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, [userId, pickupRequestId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { fetchData, data, isCompleted, isLoading, isError };
};
