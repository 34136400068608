import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PickupRequestFormItem from '../molecules/PickupRequestFormItem';
import { PickupRequest } from '../../hooks/useGetPickupRequest';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    button: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface PickupRequestDeliveredFormProps {
  data: PickupRequest;
  deliveredPickupRequest: {
    handleSubmit: () => void;
    isLoading: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  onDeliveredDialogOpen: () => void;
}

const PickupRequestDeliveredForm: React.FC<PickupRequestDeliveredFormProps> = ({
  data,
  deliveredPickupRequest,
  onDeliveredDialogOpen,
}) => {
  const classes = useStyles({});

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      deliveredPickupRequest.handleSubmit();
      onDeliveredDialogOpen();
    },
  });

  const preferredDate = data.preferredDate
    ? DateTime.fromFormat(data.preferredDate, 'yyyy-MM-dd').toFormat(
        'yyyy/MM/dd',
      )
    : '';
  const preferredTimeZone = data.preferredTimeZone
    ? data.preferredTimeZone.replace(/(\d+)-(\d+)/, '$1時-$2時')
    : '';

  return (
    <>
      <Box component="dl" overflow="hidden">
        <PickupRequestFormItem
          label="申し込み日時"
          value={DateTime.fromMillis(data.createAt, {
            zone: 'UTC',
          })
            .toLocal()
            .toFormat('yyyy/MM/dd HH:mm')}
        />
        <PickupRequestFormItem
          label="集荷希望日時"
          value={`${
            preferredDate + preferredTimeZone
              ? `${preferredDate + ' ' + preferredTimeZone}`
              : ''
          }`}
        />
        <PickupRequestFormItem label="注文番号" value={data.pickupRequestId} />
        <PickupRequestFormItem label="お名前" value={data.fullName} />
        <PickupRequestFormItem
          label="ご住所"
          value={`\u3012 ${data.postalCode} ${data.address1} ${
            data.address2 || ''
          }`}
        />
        <PickupRequestFormItem
          label="電話番号"
          value={<a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box my={4}>
          {data.status === 'delivering' && (
            <Button color="primary" fullWidth type="submit" variant="contained">
              配達完了にする
            </Button>
          )}
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            component={Link}
            to="/"
          >
            一覧に戻る
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PickupRequestDeliveredForm;
