import React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    label: {
      float: 'left',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    value: {
      textIndent: '-1rem',
      marginLeft: '35%',
      marginTop: theme.spacing(2),
      '&:before': {
        content: "':'",
        paddingRight: theme.spacing(1.6),
      },
    },
  }),
);

interface DeliveryReserveDetailItemProps {
  label: string;
  value: string | number | React.ReactElement<HTMLAnchorElement>;
}

const DeliveryReserveDetailItem: React.FC<DeliveryReserveDetailItemProps> = ({
  label,
  value,
}) => {
  const classes = useStyles({});

  return (
    <>
      <dt className={classes.label}>{label}</dt>
      <dd className={classes.value}>{value || '未設定'}</dd>
    </>
  );
};

export default DeliveryReserveDetailItem;
