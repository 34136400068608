import * as React from 'react';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface GenericTemplateProps {
  header: React.FunctionComponent;
  footer: React.FunctionComponent;
  mainContent: React.ReactElement;
}

const useStyles = makeStyles(
  (): StyleRules => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      height: '100%',
    },
    header: {
      minHeight: '64px',
    },
    footer: {
      width: '100%',
      marginTop: 'auto',
    },
  }),
);

const GenericTemplate: React.FC<GenericTemplateProps> = (props) => {
  const { header: Header, footer: Footer, mainContent } = props;
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header />
      </div>
      {mainContent}
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default GenericTemplate;
