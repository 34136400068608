import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface MessageBoxProps {
  children: React.ReactNode;
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    messageBox: {
      background: '#eee',
      boxShadow: 'none',
      color: theme.palette.common.black,
    },
  }),
);

const MessageBox: React.FC<MessageBoxProps> = ({ children, color }) => {
  const classes = useStyles({});

  return (
    <SnackbarContent
      className={classes.messageBox}
      message={
        <Typography
          component="div"
          variant="subtitle2"
          color={color || 'initial'}
        >
          {children}
        </Typography>
      }
    />
  );
};

export default MessageBox;
