import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(
  (): StyleRules => ({
    cancelButton: {
      paddingTop: '0',
    },
  }),
);

interface PickupRequestCompletedDialogProps {
  title: string;
  text: string;
  open: boolean;
  onClose: () => void;
}

const PickupRequestCompletedDialog: React.FC<PickupRequestCompletedDialogProps> = ({
  title,
  text,
  open,
  onClose,
}) => {
  const classes = useStyles({});

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List
          disablePadding
          subheader={
            <ListSubheader disableGutters>
              <Typography variant="body2" color="initial" gutterBottom>
                {text}
              </Typography>
            </ListSubheader>
          }
        ></List>
      </DialogContent>
      <DialogActions className={classes.cancelButton}>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickupRequestCompletedDialog;
