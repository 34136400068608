import { useState, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL!;

/**
 * 配達の状態
 *
 * @returns scheduledReserve: 配達待ち
 * @returns reserved: 予約済み
 * @returns delivered: 配達完了
 * @returns undeliveredForAbsence: 不在完了
 * @returns cancelledReserveBySystem: 完了
 * @returns cancelledReserveByMember: 予約解除
 * @returns received: 受取り済み
 */
export type DeliveryStatus =
  | 'scheduledReserve'
  | 'reserved'
  | 'delivered'
  | 'undeliveredForAbsence'
  | 'cancelledReserveBySystem'
  | 'cancelledReserveByMember'
  | 'received'
  | 'unknown';

export interface Delivery {
  deliveryId: string;
  trackingNumber: number;
  hubMacAddress: string;
  deliveryScheduleAt: string;
  postalCode: string;
  fullName: string;
  address1: string;
  address2?: string;
  phoneNumber: string;
  status: DeliveryStatus;
}

export const useListDelivery = (): {
  data: Delivery[];
  getData: (status?: string) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<Delivery[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (status?: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(`${REACT_APP_API_URL}/deliveries`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        params: {
          status,
        },
      });

      setData(response.data.deliveries);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { data, getData, isCompleted, isLoading, isError };
};
