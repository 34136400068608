import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import LoadingIndicator from '../atoms/LoadingIndicator';
import { PageTabContents } from '../molecules/PageTabContents';
import PageTitleBar from '../molecules/PageTitleBar';
import PickupRequestStatusLabel from '../molecules/PickupRequestStatusLabel';
import { PickupRequest } from '../../hooks/useListPickupRequest';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    wrapper: {
      overflowX: 'scroll',
    },
    tableCell: {
      padding: theme.spacing(2, 1),
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
  }),
);

interface TabContentsProps {
  isLoading: boolean;
  isCompleted: boolean;
  pickupRequests: PickupRequest[];
}

const TabContents: React.FC<TabContentsProps> = ({
  isLoading,
  isCompleted,
  pickupRequests,
}) => {
  const classes = useStyles({});
  const history = useHistory();

  const handleClick = useCallback(
    (uid, pid) => {
      history.push(`/users/${uid}/pickup-requests/${pid}`);
    },
    [history],
  );

  return isLoading ? (
    <Box mt={3}>
      <LoadingIndicator size={25} />
    </Box>
  ) : isCompleted && pickupRequests.length > 0 ? (
    <Box className={classes.wrapper}>
      <Table className={classes.table} aria-label="table">
        <TableBody>
          {pickupRequests.map((pickupRequest) => (
            <TableRow
              hover
              key={pickupRequest.pickupRequestId}
              onClick={() =>
                handleClick(pickupRequest.userId, pickupRequest.pickupRequestId)
              }
            >
              <TableCell
                component="th"
                scope="row"
                align="left"
                size="small"
                className={classes.tableCell}
              >
                {pickupRequest.pickupRequestId}
              </TableCell>
              <TableCell size="small" className={classes.tableCell}>
                &#12306;&nbsp;{pickupRequest.postalCode}&nbsp;
                {pickupRequest.address1}
                {pickupRequest.address2}
              </TableCell>
              <TableCell
                align="left"
                size="small"
                className={classes.tableCell}
              >
                {pickupRequest.fullName} 様
              </TableCell>
              <TableCell
                align="left"
                size="small"
                className={classes.tableCell}
              >
                <PickupRequestStatusLabel status={pickupRequest.status} />
              </TableCell>
              <TableCell
                align="right"
                size="small"
                className={classes.tableCell}
              >
                {DateTime.fromMillis(pickupRequest.createAt, {
                  zone: 'UTC',
                })
                  .toLocal()
                  .toFormat('yyyy/MM/dd HH:mm')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  ) : (
    <Box mt={2} textAlign="center">
      依頼はありません。
    </Box>
  );
};

interface PickupRequestInfoList {
  pickupRequests: PickupRequest[];
  getData: (status?: string) => void;
  isError: boolean;
  isCompleted: boolean;
  isLoading: boolean;
}

const PickupRequestInfoList: React.FC<PickupRequestInfoList> = ({
  pickupRequests,
  getData,
  isCompleted,
  isError,
  isLoading,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setTabValue(newValue);
    if (newValue === 0) getData('requested');
    if (newValue === 1) getData('approved');
    if (newValue === 2) getData('pickedup,delivering');
    if (newValue === 3) getData('delivered,requestCanceled,approveRejected');
  };

  return !isError ? (
    <>
      <Box mt={6}>
        <PageTitleBar title="依頼一覧" />
      </Box>
      <PageTabContents
        labels={['受付待ち', '集荷可能', '配送可能', '完了(その他)']}
        tabContents={[
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            pickupRequests={pickupRequests}
            key={0}
          />,
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            pickupRequests={pickupRequests}
            key={1}
          />,
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            pickupRequests={pickupRequests}
            key={2}
          />,
          <TabContents
            isLoading={isLoading}
            isCompleted={isCompleted}
            pickupRequests={pickupRequests}
            key={3}
          />,
        ]}
        tabValue={tabValue}
        onTabChange={handleTabChange}
      />
    </>
  ) : null;
};

export default PickupRequestInfoList;
