import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AuthService from '../../utils/AuthService';
import FooterBar from '../organisms/FooterBar';
import HeaderBar from '../organisms/HeaderBar';
import GenericTemplate from '../templates/GenericTemplate';

const AFTER_LOGIN_REDIRECT_URL = 'redirect_url';

const useStyles = makeStyles(
  (): StyleRules => ({
    wrapper: {
      height: '100%',
    },
  }),
);

const CallbackPage: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      await AuthService.handleAuthentication({
        redirectUri: `${window.location.origin}/callback`,
      });

      if (AuthService.isAuthenticated()) {
        const redirectUrl = Cookies.get(AFTER_LOGIN_REDIRECT_URL);

        Cookies.remove(AFTER_LOGIN_REDIRECT_URL);

        if (redirectUrl) {
          window.location.assign(redirectUrl);
        } else {
          window.location.assign('/');
        }

        return;
      }

      window.location.assign('/login');
    })();
  });

  return (
    <GenericTemplate
      header={HeaderBar}
      footer={FooterBar}
      mainContent={
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.wrapper}
        >
          <CircularProgress />
        </Grid>
      }
    />
  );
};

export default CallbackPage;
