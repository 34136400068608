import React, { useCallback, useEffect, useState } from 'react';
import jsQR, { QRCode } from 'jsqr';
import { Point } from 'jsqr/dist/locator';

import MediaDeviceService from '../utils/MediaDeviceService';

export const useQrCode = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  videoRef: React.RefObject<HTMLVideoElement>,
): {
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  scanned: boolean;
  setScanned: React.Dispatch<React.SetStateAction<boolean>>;
  result: string;
  setResult: React.Dispatch<React.SetStateAction<string>>;
} => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<boolean>(false);
  const [result, setResult] = useState<string>('');
  const [scanned, setScanned] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const drawLine = useCallback(
    (
      canvasContext: CanvasRenderingContext2D,
      begin: Point,
      end: Point,
      color: string,
    ) => {
      canvasContext.beginPath();
      canvasContext.moveTo(begin.x, begin.y);
      canvasContext.lineTo(end.x, end.y);
      canvasContext.lineWidth = 4;
      canvasContext.strokeStyle = color;
      canvasContext.stroke();
    },
    [],
  );

  const setDrawLines = useCallback(
    (canvasContext: CanvasRenderingContext2D, qrCode: QRCode) => {
      drawLine(
        canvasContext,
        qrCode.location.topLeftCorner,
        qrCode.location.topRightCorner,
        '#FF3B58',
      );
      drawLine(
        canvasContext,
        qrCode.location.topRightCorner,
        qrCode.location.bottomRightCorner,
        '#FF3B58',
      );
      drawLine(
        canvasContext,
        qrCode.location.bottomRightCorner,
        qrCode.location.bottomLeftCorner,
        '#FF3B58',
      );
      drawLine(
        canvasContext,
        qrCode.location.bottomLeftCorner,
        qrCode.location.topLeftCorner,
        '#FF3B58',
      );
    },
    [drawLine],
  );

  const readqrCode = useCallback(
    (canvasElement: HTMLCanvasElement, videoElement: HTMLVideoElement) => {
      canvasElement.hidden = true;

      if (!playing) {
        return;
      }

      const canvasContext = canvasElement.getContext('2d');

      if (!canvasContext) {
        return;
      }

      const canvasWidth = 328;
      const canvasHeight = 246;

      canvasContext.drawImage(videoElement, 0, 0, canvasWidth, canvasHeight);

      const imageData = canvasContext.getImageData(
        0,
        0,
        canvasWidth,
        canvasHeight,
      );

      const qrCode = jsQR(imageData.data, canvasWidth, canvasHeight, {
        inversionAttempts: 'dontInvert',
      });

      if (qrCode) {
        setDrawLines(canvasContext, qrCode);

        canvasElement.hidden = false;

        setResult(qrCode.data);
        setPlaying(false);
        setScanned(true);
      } else {
        setTimeout(() => {
          readqrCode(canvasElement, videoElement);
        }, 1000);
      }
    },
    [playing, setDrawLines],
  );

  const startVideo = useCallback(
    (videoElement: HTMLVideoElement, mediaStream: MediaStream) => {
      videoElement.setAttribute('autoplay', '');
      videoElement.setAttribute('muted', '');
      videoElement.setAttribute('playsinline', '');

      if (videoElement.srcObject !== undefined) {
        videoElement.srcObject = mediaStream;
      } else {
        // @ts-ignore
        videoElement.src = window.URL.createObjectURL(mediaStream);
      }

      videoElement.onloadedmetadata = () => {
        videoElement.play();
      };

      videoElement.hidden = false;
    },
    [],
  );

  const endVideo = useCallback(
    (videoElement: HTMLVideoElement, mediaStream: MediaStream | null) => {
      videoElement.hidden = true;

      if (videoElement.srcObject !== undefined) {
        videoElement.srcObject = null;
      } else {
        // @ts-ignore
        videoElement.src = window.URL.createObjectURL(null);
      }

      if (mediaStream) {
        const tracks = mediaStream.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }

      setMediaStream(null);
    },
    [],
  );

  useEffect(() => {
    (async () => {
      const canvasElement = canvasRef.current;
      const videoElement = videoRef.current;

      if (!canvasElement || !videoElement || !playing || played) {
        return;
      }

      setPlayed(true);

      if (mediaStream) {
        startVideo(videoElement, mediaStream);
      } else {
        const stream = await MediaDeviceService.getUserVideo();

        setMediaStream(stream);
        startVideo(videoElement, stream);
      }

      readqrCode(canvasElement, videoElement);
    })();
  }, [
    playing,
    played,
    canvasRef,
    videoRef,
    mediaStream,
    startVideo,
    readqrCode,
  ]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement || playing) {
      return;
    }

    endVideo(videoElement, mediaStream);
    setPlayed(false);
  }, [playing, videoRef, mediaStream, endVideo]);

  return { playing, setPlaying, scanned, setScanned, result, setResult };
};
