import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles/';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from './theme';
import AccountPage from './components/pages/AccountPage';
import AuthPage from './components/pages/AuthPage';
import CallbackPage from './components/pages/CallbackPage';
import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';
import PickupRequestDetailPage from './components/pages/PickupRequestDetailPage';
import DeliveryReserveDetailPage from './components/pages/DeliveryReserveDetailPage';
import TermsPage from './components/pages/TermsPage';
import PrivacyPage from './components/pages/PrivacyPage';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/callback" component={CallbackPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <AuthPage>
            <Switch>
              <Route path="/account" component={AccountPage} />
              <Route
                path="/users/:userId/pickup-requests/:pickupRequestId"
                component={PickupRequestDetailPage}
              />
              <Route
                path="/deliveries/:deliveryId"
                component={DeliveryReserveDetailPage}
              />
              <Route path="/" component={HomePage} />
            </Switch>
          </AuthPage>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
