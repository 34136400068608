import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import { StyleRules } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import AuthService from '../../utils/AuthService';
import HeaderBarLogo from '../molecules/HeaderBarLogo';
import HeaderBarMenu from '../molecules/HeaderBarMenu';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    logoText: {
      display: 'block',
      color: theme.palette.common.white,
      lineHeight: 1,
      fontWeight: 'bold',
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
    },
    subTitle: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
  }),
);

const HeaderBar: React.FC = () => {
  const classes = useStyles({});

  const handleLogoutClick = React.useCallback(() => {
    AuthService.logout({
      redirectTo: `${window.location.origin}/callback`,
    });
  }, []);
  const isAuthenticated = AuthService.isAuthenticated();

  return (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <HeaderBarLogo to="/" />
        <Typography variant="subtitle1" component="h2" color="inherit">
          <Link to="/" className={classes.logoText}>
            あずけるBOX
            <br />
            <span className={classes.subTitle}>ビジネスサイト</span>
          </Link>
        </Typography>
        {isAuthenticated && <HeaderBarMenu onLogoutClick={handleLogoutClick} />}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
